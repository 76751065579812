import light_bg from '../../images/bg/light.png';
import yellow_icon1 from '../../images/challenge/Y1.png';
import yellow_icon2 from '../../images/challenge/Y2.png';
import yellow_icon3 from '../../images/challenge/Y3.png';
import yellow_icon4 from '../../images/challenge/Y4.png';
import yellow_icon5 from '../../images/challenge/Y5.png';
import enterprise_light1 from '../../images/enterprise-logo/light/light1.png';
import enterprise_light2 from '../../images/enterprise-logo/light/light2.png';
import enterprise_light3 from '../../images/enterprise-logo/light/light3.png';
import enterprise_light4 from '../../images/enterprise-logo/light/light4.png';
import enterprise_light5 from '../../images/enterprise-logo/light/light5.png';
import enterprise_light6 from '../../images/enterprise-logo/light/light6.png';
import classify_light from '../../images/industry/light1.png';
import screenshot_light1 from '../../images/screenshot/light/light1.png';
import screenshot_light2 from '../../images/screenshot/light/light2.png';
import screenshot_light3 from '../../images/screenshot/light/light3.png';
import screenshot_light4 from '../../images/screenshot/light/light4.png';
import screenshot_light5 from '../../images/screenshot/light/light5.png';
import screenshot_light6 from '../../images/screenshot/light/light6.png';

const light = {
    bgUrl: light_bg,
    classifyIcon: classify_light,
    title: '专业照明开关管理',
    content:
        '中国照明产业经过多年的发展，<br/> 已经成为全球最大的照明产品生产国和出口国。LED照明技术的日趋成熟。',
    list1: [
        {
            imgUrl: yellow_icon1,
            describe:
                '照明电子行业货品品类多、品牌多、但功能雷同、形状不一，时常出现销售单里选错货品。',
            rgba: '248, 247, 207, 0.5'
        },
        {
            imgUrl: yellow_icon2,
            describe: '电子芯片每日价格都不同，需要及时更新货品价目表，客户根据此来订货。',
            rgba: '248, 247, 207, 0.5'
        },
        {
            imgUrl: yellow_icon3,
            describe: '电子芯片单价低，往往需要精确到小数点后4位。',
            rgba: '248, 247, 207, 0.5'
        },
        {
            imgUrl: yellow_icon4,
            describe: '订单价值高，款项出入额大，形成财务漏洞，损失巨大。',
            rgba: '248, 247, 207, 0.5'
        },
        {
            imgUrl: yellow_icon5,
            describe: '货品种类多，日成交量大，出入库需要有详细的记录。',
            rgba: '248, 247, 207, 0.5'
        }
    ],
    list2: [
        {
            name: '货品',
            describe: '货品分类管理'
        },
        {
            name: '策略',
            describe: '价格策略管理'
        },
        {
            name: '提醒',
            describe: '预交日期提醒'
        },
        {
            name: '供应商',
            describe: '供应商价格策略'
        },
        {
            name: '下单',
            describe: '移动端快速下单'
        },
        {
            name: '储位',
            describe: '仓库储位精细化管理'
        }
    ],
    list3: [
        {
            imgUrl: screenshot_light1,
            name: '货品管理'
        },
        {
            imgUrl: screenshot_light2,
            name: '价格明细表'
        },
        {
            imgUrl: screenshot_light3,
            name: '小数位数管理'
        },
        {
            imgUrl: screenshot_light4,
            name: '账款管理'
        },
        {
            imgUrl: screenshot_light5,
            name: '出入库类报表'
        },
        {
            imgUrl: screenshot_light6,
            name: '库存预警'
        }
    ],
    list4: [
        {
            imgUrl: enterprise_light1,
            name: 'LUMIM'
        },
        {
            imgUrl: enterprise_light2,
            name: '智能充电'
        },
        {
            imgUrl: enterprise_light3,
            name: 'Hightech'
        },
        {
            imgUrl: enterprise_light4,
            name: '全郎'
        },
        {
            imgUrl: enterprise_light5,
            name: 'IZIT'
        },
        {
            imgUrl: enterprise_light6,
            name: '恩际'
        }
    ]
};

export default light;
