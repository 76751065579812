import phone_bg from '../../images/bg/phone.jpg';
import green_icon1 from '../../images/challenge/g1.png';
import green_icon2 from '../../images/challenge/g2.png';
import green_icon3 from '../../images/challenge/g3.png';
import green_icon4 from '../../images/challenge/g4.png';
import green_icon5 from '../../images/challenge/g5.png';
import logo12 from '../../images/enterprise-logo/logo/logo12.png';
import logo13 from '../../images/enterprise-logo/logo/logo13.png';
import logo2 from '../../images/enterprise-logo/logo/logo2.png';
import logo21 from '../../images/enterprise-logo/logo/logo21.png';
import logo22 from '../../images/enterprise-logo/logo/logo22.png';
import logo7 from '../../images/enterprise-logo/logo/logo7.png';
import classify_phone from '../../images/industry/phone1.png';
import screenshot_phone1 from '../../images/screenshot/phone/phone1.png';
import screenshot_phone2 from '../../images/screenshot/phone/phone2.png';
import screenshot_phone3 from '../../images/screenshot/phone/phone3.png';
import screenshot_phone4 from '../../images/screenshot/phone/phone4.png';
import screenshot_phone5 from '../../images/screenshot/phone/phone5.png';
import screenshot_phone6 from '../../images/screenshot/phone/phone6.png';

const phone = {
    bgUrl: phone_bg,
    classifyIcon: classify_phone,
    title: '专业手机数码管理',
    content:
        '智能时代来临，手机等数码产品已成为人们生活中不可或缺的一部分，<br/> 手机数码行业也因此迎来了更多的商机，也有着更激烈的行业竞争。',
    list1: [
        {
            imgUrl: green_icon1,
            describe: '数码产品更新换代快，品类多、型号多，价格变动快，产品管理困难。',
            rgba: '205, 239, 250, 0.5'
        },
        {
            imgUrl: green_icon2,
            describe:
                '产品标准化高，客户流动性大，需要给客户提供专属的的优惠折扣，才能长期吸引客户。',
            rgba: '205, 239, 250, 0.5'
        },
        {
            imgUrl: green_icon3,
            describe: '大家电销售完成后，需要送货上门安装；订单多，排单送货就会混乱，客户投诉多。',
            rgba: '205, 239, 250, 0.5'
        },
        {
            imgUrl: green_icon4,
            describe:
                '手机家电产品属于贵重产品，为了防止盗窃丢失方便跟踪维护，每个产品都有一个唯一的机器码（序列号）',
            rgba: '205, 239, 250, 0.5'
        },
        {
            imgUrl: green_icon5,
            describe: '手机家电类产品经常有线上预售、实际仓库库存被预售占用问题 无法解决',
            rgba: '205, 239, 250, 0.5'
        }
    ],
    list2: [
        {
            name: '货品',
            describe: '货品分类管理'
        },
        {
            name: '序列号',
            describe: '序列号管理'
        },
        {
            name: '价格',
            describe: '销售价格策略管理'
        },
        {
            name: '日期',
            describe: '预交日期提醒'
        },
        {
            name: '库存',
            describe: '库存占用管理'
        },
        {
            name: '预警',
            describe: '库存预警'
        }
    ],
    list3: [
        {
            imgUrl: screenshot_phone1,
            name: '订单超期预警'
        },
        {
            imgUrl: screenshot_phone2,
            name: '货品分类管理'
        },
        {
            imgUrl: screenshot_phone3,
            name: '库存预警'
        },
        {
            imgUrl: screenshot_phone4,
            name: '库存占用量管理'
        },
        {
            imgUrl: screenshot_phone5,
            name: '销售价格策略'
        },
        {
            imgUrl: screenshot_phone6,
            name: '序列号管理'
        }
    ],
    list4: [
        {
            imgUrl: logo2,
            name: '光大银行'
        },
        {
            imgUrl: logo13,
            name: '虎山电子'
        },
        {
            imgUrl: logo21,
            name: '咖啡路由器'
        },
        {
            imgUrl: logo22,
            name: '普适导航'
        },
        {
            imgUrl: logo7,
            name: 'DOLSI'
        },
        {
            imgUrl: logo12,
            name: '智能充电的领航者'
        }
    ]
};

export default phone;