import equipment_bg from '../../images/bg/equipment.png';
import orange_icon1 from '../../images/challenge/O1.png';
import orange_icon2 from '../../images/challenge/O2.png';
import orange_icon3 from '../../images/challenge/O3.png';
import orange_icon4 from '../../images/challenge/O4.png';
import orange_icon5 from '../../images/challenge/O5.png';
import enterprise_equipment1 from '../../images/enterprise-logo/equipment/equipment1.png';
import enterprise_equipment2 from '../../images/enterprise-logo/equipment/equipment2.png';
import enterprise_equipment3 from '../../images/enterprise-logo/equipment/equipment3.png';
import enterprise_equipment4 from '../../images/enterprise-logo/equipment/equipment4.png';
import enterprise_equipment5 from '../../images/enterprise-logo/equipment/equipment5.png';
import enterprise_equipment6 from '../../images/enterprise-logo/equipment/equipment6.png';
import classify_equipment from '../../images/industry/equipment1.png';
import screenshot_equipment1 from '../../images/screenshot/equipment/equipment1.png';
import screenshot_equipment2 from '../../images/screenshot/equipment/equipment2.png';
import screenshot_equipment3 from '../../images/screenshot/equipment/equipment3.png';
import screenshot_equipment4 from '../../images/screenshot/equipment/equipment4.png';
import screenshot_equipment5 from '../../images/screenshot/equipment/equipment5.png';
import screenshot_equipment6 from '../../images/screenshot/equipment/equipment6.png';

const equipment = {
    bgUrl: equipment_bg,
    classifyIcon: classify_equipment,
    title: '专业行业设备管理',
    content:
        '行业专用设备是指专门用于某一行业的生产、加工或者服务设备。<br/> 主要包括工程、农业、矿业、塑料、橡胶、化工等制造业行业专用机械，以及医疗、金融、餐饮等服务业专用设备。',
    list1: [
        {
            imgUrl: orange_icon1,
            describe: '汽配行业货品品类多、品牌多、规格多，货品管理困难。',
            rgba: '250, 231, 203, 0.5'
        },
        {
            imgUrl: orange_icon2,
            describe: '汽修汽配货品会周期性消耗，需要根据客户消耗周期、预估购买时间，促进销售。',
            rgba: '250, 231, 203, 0.5'
        },
        {
            imgUrl: orange_icon3,
            describe:
                '汽修汽配货品需要按客户需求去采购、订货， 没有良好的客户跟踪系统，就会出现漏采、或者交付不及时等问题。',
            rgba: '250, 231, 203, 0.5'
        },
        {
            imgUrl: orange_icon4,
            describe: '批发销量大，订单多，很多客户结算方式是月结，应收款对帐工作量巨大。',
            rgba: '250, 231, 203, 0.5'
        },
        {
            imgUrl: orange_icon5,
            describe: '销售很多靠熟客，需要有良好的VIP管理系统，提供专属的优惠折扣等。',
            rgba: '250, 231, 203, 0.5'
        }
    ],
    list2: [
        {
            name: '货品',
            describe: '货品分类管理'
        },
        {
            name: '查询',
            describe: '销售报表查询'
        },
        {
            name: '价格',
            describe: '客户价格策略'
        },
        {
            name: '核销',
            describe: '应付账款统一核销'
        },
        {
            name: '以销定购',
            describe: '以销定购'
        },
        {
            name: '报表',
            describe: '账款报表实时查询'
        }
    ],
    list3: [
        {
            imgUrl: screenshot_equipment1,
            name: '货品分类管理'
        },
        {
            imgUrl: screenshot_equipment2,
            name: '销售报表'
        },
        {
            imgUrl: screenshot_equipment3,
            name: '客户价格策略'
        },
        {
            imgUrl: screenshot_equipment4,
            name: '应付账款统一核销.'
        },
        {
            imgUrl: screenshot_equipment5,
            name: '以销定购'
        },
        {
            imgUrl: screenshot_equipment6,
            name: '账款报表实时查询'
        }
    ],
    list4: [
        {
            imgUrl: enterprise_equipment1,
            name: '虎山电子'
        },
        {
            imgUrl: enterprise_equipment2,
            name: '精力'
        },
        {
            imgUrl: enterprise_equipment3,
            name: 'Hightech'
        },
        {
            imgUrl: enterprise_equipment4,
            name: 'DD'
        },
        {
            imgUrl: enterprise_equipment5,
            name: '美佳丽实验室'
        },
        {
            imgUrl: enterprise_equipment6,
            name: '科霖智能'
        }
    ]
};

export default equipment;
