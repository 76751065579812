import jewerly_bg from '../../images/bg/jewerly.png';
import pink_icon1 from '../../images/challenge/p1.png';
import pink_icon2 from '../../images/challenge/p2.png';
import pink_icon3 from '../../images/challenge/p3.png';
import pink_icon4 from '../../images/challenge/p4.png';
import pink_icon5 from '../../images/challenge/p5.png';
import enterprise_jewerly1 from '../../images/enterprise-logo/jewerly/jewerly1.png';
import enterprise_jewerly2 from '../../images/enterprise-logo/jewerly/jewerly2.png';
import enterprise_jewerly3 from '../../images/enterprise-logo/jewerly/jewerly3.png';
import enterprise_jewerly4 from '../../images/enterprise-logo/jewerly/jewerly4.png';
import enterprise_jewerly5 from '../../images/enterprise-logo/jewerly/jewerly5.png';
import enterprise_jewerly6 from '../../images/enterprise-logo/jewerly/jewerly6.png';
import classify_jewerly from '../../images/industry/jewerly1.png';
import screenshot_jewerly1 from '../../images/screenshot/jewerly/jewerly1.png';
import screenshot_jewerly2 from '../../images/screenshot/jewerly/jewerly2.png';
import screenshot_jewerly3 from '../../images/screenshot/jewerly/jewerly3.png';
import screenshot_jewerly4 from '../../images/screenshot/jewerly/jewerly4.png';
import screenshot_jewerly5 from '../../images/screenshot/jewerly/jewerly5.png';
import screenshot_jewerly6 from '../../images/screenshot/jewerly/jewerly6.png';

const jewerly = {
    bgUrl: jewerly_bg,
    classifyIcon: classify_jewerly,
    title: '专业珠宝配饰管理',
    content:
        '珠宝首饰及有关物品的制造是指以金、银、铂等贵金属及其合金以及钻石、宝石、玉石、翡翠、珍珠等为原料，<br/> 经金属加工和连结组合、镶嵌等工艺加工制作各种图案的装饰品的制作活动。',
    list1: [
        {
            imgUrl: pink_icon1,
            describe: '珠宝货品风格多样化、品牌多、种类多，款式多，货品管理困难。',
            rgba: '252, 230, 231, 0.5'
        },
        {
            imgUrl: pink_icon2,
            describe:
                '珠宝行业货品单价较高，误操作或不规范操作都可能给企业带来较大损失，需要管理系统有完备的日志来做追溯和追责。',
            rgba: '252, 230, 231, 0.5'
        },
        {
            imgUrl: pink_icon3,
            describe: '珠宝货品很多是个性化货品，标准化程度低，需要经常打印货品条码。',
            rgba: '252, 230, 231, 0.5'
        },
        {
            imgUrl: pink_icon4,
            describe:
                '珠宝行业货品数据、客户资料是机密信息，系统安全非常至关重要，帐号光靠密码保护不能满足安全要求。',
            rgba: '252, 230, 231, 0.5'
        },
        {
            imgUrl: pink_icon5,
            describe: '价格高，属于贵重货品，为了防止盗窃丢失每个货品都有一个唯一的序列号。',
            rgba: '252, 230, 231, 0.5'
        }
    ],
    list2: [
        {
            name: '货品',
            describe: '货品分类管理'
        },
        {
            name: '策略',
            describe: '价格策略管理'
        },
        {
            name: '提醒',
            describe: '预交日期提醒'
        },
        {
            name: '供应商',
            describe: '供应商价格策略'
        },
        {
            name: '下单',
            describe: '移动端快速下单'
        },
        {
            name: '储位',
            describe: '仓库储位精细化管理'
        }
    ],
    list3: [
        {
            imgUrl: screenshot_jewerly1,
            name: '货品分类管理'
        },
        {
            imgUrl: screenshot_jewerly2,
            name: '日志管理'
        },
        {
            imgUrl: screenshot_jewerly3,
            name: '条码管理'
        },
        {
            imgUrl: screenshot_jewerly4,
            name: '账号权限管理'
        },
        {
            imgUrl: screenshot_jewerly5,
            name: '序列号管理'
        },
        {
            imgUrl: screenshot_jewerly6,
            name: '条件审批流程'
        }
    ],
    list4: [
        {
            imgUrl: enterprise_jewerly1,
            name: 'IZIT'
        },
        {
            imgUrl: enterprise_jewerly2,
            name: '金嘉利钻石'
        },
        {
            imgUrl: enterprise_jewerly3,
            name: '越尚珠宝'
        },
        {
            imgUrl: enterprise_jewerly4,
            name: 'SUMSH'
        },
        {
            imgUrl: enterprise_jewerly5,
            name: '狄腾马'
        },
        {
            imgUrl: enterprise_jewerly6,
            name: '恩际'
        }
    ]
};

export default jewerly;
