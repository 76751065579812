import beauty from './beauty';
import car from './car';
import chemical from './chemical';
import clothing from './clothing';
import compute from './compute';
import electric from './electric';
import equipment from './equipment';
import food from './food';
import goods from './goods';
import handicraft from './handicraft';
import instrument from './instrument';
import jewerly from './jewerly';
import light from './light';
import metals from './metals';
import pack from './pack';
import pattern from './pattern';
import phone from './phone';
import rice from './rice';
import sport from './sport';
import toy from './toy';
import weave from './weave';

const data = {
    beauty,
    car,
    chemical,
    clothing,
    compute,
    electric,
    equipment,
    food,
    goods,
    handicraft,
    instrument,
    jewerly,
    light,
    metals,
    pack,
    pattern,
    phone,
    rice,
    sport,
    toy,
    weave
};

export default data;
