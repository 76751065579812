import food_bg from '../../images/bg/food.jpg';

import orange_icon1 from '../../images/challenge/O1.png';
import orange_icon2 from '../../images/challenge/O2.png';
import orange_icon3 from '../../images/challenge/O3.png';
import orange_icon4 from '../../images/challenge/O4.png';
import orange_icon5 from '../../images/challenge/O5.png';

import logo1 from '../../images/enterprise-logo/logo/logo1.png';
import logo10 from '../../images/enterprise-logo/logo/logo10.png';
import logo15 from '../../images/enterprise-logo/logo/logo15.png';
import logo16 from '../../images/enterprise-logo/logo/logo16.png';
import logo18 from '../../images/enterprise-logo/logo/logo18.png';
import logo5 from '../../images/enterprise-logo/logo/logo5.png';

import classify_food from '../../images/industry/food1.png';

import screenshot_food1 from '../../images/screenshot/food/food1.png';
import screenshot_food2 from '../../images/screenshot/food/food2.png';
import screenshot_food3 from '../../images/screenshot/food/food3.png';
import screenshot_food4 from '../../images/screenshot/food/food4.png';
import screenshot_food5 from '../../images/screenshot/food/food5.png';
import screenshot_food6 from '../../images/screenshot/food/food6.png';

// import { useStaticQuery, graphql } from 'gatsby';

// const imgData = useStaticQuery(graphql`
//     query {
//         bg: file(relativePath: { eq: "images/bg/food.jpg" }) {
//             childImageSharp {
//                 fixed(height: 342) {
//                     ...GatsbyImageSharpFixed
//                 }
//             }
//         }
//         icon: allFile(
//             filter: { relativePath: { regex: "images/challenge/.*/" } }
//             sort: { fields: name, order: ASC }
//         ) {
//             nodes {
//                 childImageSharp {
//                     fixed(height: 46, width: 49) {
//                         ...GatsbyImageSharpFixed
//                     }
//                 }
//             }
//         }
//         logo: allFile(
//             filter: { relativePath: { regex: "images/enterprise-logo/logo/.*/" } }
//             sort: { fields: name, order: ASC }
//         ) {
//             nodes {
//                 childImageSharp {
//                     fixed(height: 57, width: 172) {
//                         ...GatsbyImageSharpFixed
//                     }
//                 }
//             }
//         }
//         classify: file(relativePath: { eq: "images/industry/food1.png" }) {
//             childImageSharp {
//                 fixed(height: 97, width: 119) {
//                     ...GatsbyImageSharpFixed
//                 }
//             }
//         }
//         screenshot: allFile(
//             filter: { relativePath: { regex: "images/screenshot/food/.*/" } }
//             sort: { fields: name, order: ASC }
//         ) {
//             nodes {
//                 childImageSharp {
//                     fixed(height: 200, width: 480) {
//                         ...GatsbyImageSharpFixed
//                     }
//                 }
//             }
//         }
//     }
// `);

const food = {
    bgUrl: food_bg,
    classifyIcon: classify_food,
    title: '专业食品饮料管理',
    content:
        '民以食为天。食品饮料仍然在中国居民消费结构中占据重要的位置，<br/> 且大众对食品饮料的需求多、需求广。食品饮料行业的分销渠道也变得日益多样化。',
    list1: [
        {
            imgUrl: orange_icon1,
            describe: '食品饮料行业品类多、品牌多、规格多、单位多，产品管理困难。',
            rgba: '250, 231, 203, 0.5'
        },
        {
            imgUrl: orange_icon2,
            describe: '食品饮料行业品类多、品牌多、规格多、单位多，产品管理困难。',
            rgba: '250, 231, 203, 0.5'
        },
        {
            imgUrl: orange_icon3,
            describe: '在做商超配送时，客户看货下单，需要用手机随时随地开销售单。',
            rgba: '250, 231, 203, 0.5'
        },
        {
            imgUrl: orange_icon4,
            describe: '批发销量大，订单多，很多客户结算方式是月结，应收款对帐工作量巨大。',
            rgba: '250, 231, 203, 0.5'
        },
        {
            imgUrl: orange_icon5,
            describe:
                '食品行业的保质期管理尤为重要，不同批次的食品过期时间不一样，要做到销售时先入库的食品先销售出库。',
            rgba: '250, 231, 203, 0.5'
        }
    ],
    list2: [
        {
            name: '批次',
            describe: '批次管理'
        },
        {
            name: '预警',
            describe: '保质期预警'
        },
        {
            name: '价格',
            describe: '销售价格策略管理'
        },
        {
            name: '下单',
            describe: '手机端快速下单'
        },
        {
            name: '查询',
            describe: '手机端实时查询'
        },
        {
            name: '核销',
            describe: '应收账款统一核销'
        }
    ],
    list3: [
        {
            imgUrl: screenshot_food1,
            name: '批次管理'
        },
        {
            imgUrl: screenshot_food2,
            name: '保质期预警'
        },
        {
            imgUrl: screenshot_food3,
            name: '销售价格策略'
        },
        {
            imgUrl: screenshot_food4,
            name: '应收帐款统一核销'
        },
        {
            imgUrl: screenshot_food5,
            name: '手机快速下单'
        },
        {
            imgUrl: screenshot_food6,
            name: '移动端实时查询'
        }
    ],
    list4: [
        {
            imgUrl: logo10,
            name: '嗨贝花甲'
        },
        {
            imgUrl: logo16,
            name: '蓝郡咖啡'
        },
        {
            imgUrl: logo15,
            name: '潮客两面'
        },
        {
            imgUrl: logo1,
            name: '中粮'
        },
        {
            imgUrl: logo18,
            name: '康顿餐饮'
        },
        {
            imgUrl: logo5,
            name: '桂林米粉'
        }
    ]
};

export default food;