import car_bg from '../../images/bg/car.jpg';
import blue_icon1 from '../../images/challenge/b1.png';
import blue_icon2 from '../../images/challenge/b2.png';
import blue_icon3 from '../../images/challenge/b3.png';
import blue_icon4 from '../../images/challenge/b4.png';
import blue_icon5 from '../../images/challenge/b5.png';
import logo13 from '../../images/enterprise-logo/logo/logo13.png';
import logo14 from '../../images/enterprise-logo/logo/logo14.png';
import logo22 from '../../images/enterprise-logo/logo/logo22.png';
import logo23 from '../../images/enterprise-logo/logo/logo23.png';
import logo29 from '../../images/enterprise-logo/logo/logo29.png';
import logo9 from '../../images/enterprise-logo/logo/logo9.png';
import classify_car from '../../images/industry/car1.png';
import screenshot_car1 from '../../images/screenshot/car/car1.png';
import screenshot_car2 from '../../images/screenshot/car/car2.png';
import screenshot_car3 from '../../images/screenshot/car/car3.png';
import screenshot_car4 from '../../images/screenshot/car/car4.png';
import screenshot_car5 from '../../images/screenshot/car/car5.png';
import screenshot_car6 from '../../images/screenshot/car/car6.png';

const car = {
    bgUrl: car_bg,
    classifyIcon: classify_car,
    title: '专业汽车汽配管理',
    content:
        '汽车配件是构成汽车整体的各个单元及服务于汽车的一种产品。汽车配件的种类繁多，<br/> 随着人们生活水平的提高，人们对汽车的消费也越来越多，汽车配件的这个市场变得也越来越大。<br/>近些年来汽车及汽车配件制造厂也在飞速地发展。',
    list1: [
        {
            imgUrl: blue_icon1,
            describe: '汽配行业产品品类多、品牌多、规格多，产品管理困难。',
            rgba: '225, 240, 253, 0.5'
        },
        {
            imgUrl: blue_icon2,
            describe: '汽修汽配产品会周期性消耗，需要根据客户消耗周期、预估购买时间，促进销售。',
            rgba: '225, 240, 253, 0.5'
        },
        {
            imgUrl: blue_icon3,
            describe:
                '汽修汽配产品需要按客户需求去采购、订货， 没有良好的客户跟踪系统，就会出现漏采、或者交付不及时等问题。',
            rgba: '225, 240, 253, 0.5'
        },
        {
            imgUrl: blue_icon4,
            describe: '批发销量大，订单多，很多客户结算方式是月结，应收款对帐工作量巨大。',
            rgba: '225, 240, 253, 0.5'
        },
        {
            imgUrl: blue_icon5,
            describe: '销售很多靠熟客，需要有良好的VIP管理系统，提供专属的优惠折扣等。',
            rgba: '225, 240, 253, 0.5'
        }
    ],
    list2: [
        {
            name: '货品',
            describe: '货品分类管理'
        },
        {
            name: '查询',
            describe: '销售报表查询'
        },
        {
            name: '价格',
            describe: '客户策略管理'
        },
        {
            name: '账款',
            describe: '应付账款统一核销'
        },
        {
            name: '进销货',
            describe: '以销定购'
        },
        {
            name: '报表',
            describe: '账款报表实时查询'
        }
    ],
    list3: [
        {
            imgUrl: screenshot_car1,
            name: '货品分类管理'
        },
        {
            imgUrl: screenshot_car2,
            name: '客户价格策略'
        },
        {
            imgUrl: screenshot_car3,
            name: '销售价格策略'
        },
        {
            imgUrl: screenshot_car4,
            name: '账款报表实时查询'
        },
        {
            imgUrl: screenshot_car5,
            name: '以销定购'
        },
        {
            imgUrl: screenshot_car6,
            name: '应付账款统一核销'
        }
    ],
    list4: [
        {
            imgUrl: logo29,
            name: 'IZIZ'
        },
        {
            imgUrl: logo13,
            name: '虎山电子'
        },
        {
            imgUrl: logo14,
            name: '金炬实业'
        },
        {
            imgUrl: logo23,
            name: '恩特机械'
        },
        {
            imgUrl: logo22,
            name: '普适导航'
        },
        {
            imgUrl: logo9,
            name: '全朗'
        }
    ]
};

export default car;
