import clothing_bg from '../../images/bg/clothing.jpg';
import blue_icon1 from '../../images/challenge/b1.png';
import blue_icon2 from '../../images/challenge/b2.png';
import blue_icon3 from '../../images/challenge/b3.png';
import blue_icon4 from '../../images/challenge/b4.png';
import blue_icon5 from '../../images/challenge/b5.png';
import logo17 from '../../images/enterprise-logo/logo/logo17.png';
import logo22 from '../../images/enterprise-logo/logo/logo22.png';
import logo24 from '../../images/enterprise-logo/logo/logo24.png';
import logo26 from '../../images/enterprise-logo/logo/logo26.png';
import logo28 from '../../images/enterprise-logo/logo/logo28.png';
import logo3 from '../../images/enterprise-logo/logo/logo3.png';
import classify_clothing from '../../images/industry/clothing1.png';
import screenshot_clothing1 from '../../images/screenshot/clothing/clothing1.png';
import screenshot_clothing2 from '../../images/screenshot/clothing/clothing2.png';
import screenshot_clothing3 from '../../images/screenshot/clothing/clothing3.png';
import screenshot_clothing4 from '../../images/screenshot/clothing/clothing4.png';
import screenshot_clothing5 from '../../images/screenshot/clothing/clothing5.png';
import screenshot_clothing6 from '../../images/screenshot/clothing/clothing6.png';

const clothing = {
    bgUrl: clothing_bg,
    classifyIcon: classify_clothing,
    title: '专业服饰鞋帽管理',
    content:
        '我国是全世界最大的服装消费国和生产国。<br/> 中国已成为全世界最大的服装生产加工基地，全世界每三件服装，其中一件来自于中国生产。',
    list1: [
        {
            imgUrl: blue_icon1,
            describe:
                '服装服饰属于快时尚行业，产品更新速度快，品牌多、款式多，颜色多，尺码多，产品管理难度大。',
            rgba: '230, 242, 240, 0.5'
        },
        {
            imgUrl: blue_icon2,
            describe: '服装批发中，爆款产品，客户需求量大，订单多，需要快速开销售单。',
            rgba: '230, 242, 240, 0.5'
        },
        {
            imgUrl: blue_icon3,
            describe: '销售时需要经常查同款号，不同颜色、尺码的库存量，不能快速查询就会影响销售。',
            rgba: '230, 242, 240, 0.5'
        },
        {
            imgUrl: blue_icon4,
            describe:
                '每个批发客户进货价都不一样，销售开单时要快速查到客户之前的销售价。对大客户还要做应收款管理。',
            rgba: '230, 242, 240, 0.5'
        },
        {
            imgUrl: blue_icon5,
            describe: '连锁门店之间需要查询库存，及时调货，才能完成销售。',
            rgba: '230, 242, 240, 0.5'
        }
    ],
    list2: [
        {
            name: '货品',
            describe: '货品资料批量管理'
        },
        {
            name: '录入',
            describe: '多规格快速录入'
        },
        {
            name: '查询',
            describe: '商品库多规格查询'
        },
        {
            name: '调拨',
            describe: '库存调拨'
        },
        {
            name: '报表',
            describe: '采购报表查询'
        },
        {
            name: '查询',
            describe: '手机端实时查询库存'
        }
    ],
    list3: [
        {
            imgUrl: screenshot_clothing1,
            name: '采购报表'
        },
        {
            imgUrl: screenshot_clothing2,
            name: '多规格快速录入'
        },
        {
            imgUrl: screenshot_clothing3,
            name: '货品资料批量管理'
        },
        {
            imgUrl: screenshot_clothing4,
            name: '库存调拨'
        },
        {
            imgUrl: screenshot_clothing5,
            name: '商品库存多规格查询'
        },
        {
            imgUrl: screenshot_clothing6,
            name: '移动端实时查询'
        }
    ],
    list4: [
        {
            imgUrl: logo26,
            name: '箱包'
        },
        {
            imgUrl: logo3,
            name: '卡珀洗衣'
        },
        {
            imgUrl: logo28,
            name: '皮具'
        },
        {
            imgUrl: logo17,
            name: '卡赛欧'
        },
        {
            imgUrl: logo22,
            name: '普适导航'
        },
        {
            imgUrl: logo24,
            name: '狄腾马'
        }
    ]
};

export default clothing;
