import compute_bg from '../../images/bg/compute.png';
import blue_icon1 from '../../images/challenge/b1.png';
import blue_icon2 from '../../images/challenge/b2.png';
import blue_icon3 from '../../images/challenge/b3.png';
import blue_icon4 from '../../images/challenge/b4.png';
import blue_icon5 from '../../images/challenge/b5.png';
import logo13 from '../../images/enterprise-logo/logo/logo13.png';
import logo14 from '../../images/enterprise-logo/logo/logo14.png';
import logo27 from '../../images/enterprise-logo/logo/logo27.png';
import logo8 from '../../images/enterprise-logo/logo/logo8.png';
import logo9 from '../../images/enterprise-logo/logo/logo9.png';
import logo30 from '../../images/enterprise-logo/logo30.png';
import classify_compute from '../../images/industry/compute1.png';
import screenshot_compute1 from '../../images/screenshot/compute/compute1.png';
import screenshot_compute2 from '../../images/screenshot/compute/compute2.png';
import screenshot_compute3 from '../../images/screenshot/compute/compute3.png';
import screenshot_compute4 from '../../images/screenshot/compute/compute4.png';
import screenshot_compute5 from '../../images/screenshot/compute/compute5.png';
import screenshot_compute6 from '../../images/screenshot/compute/compute6.png';

const compute = {
    bgUrl: compute_bg,
    classifyIcon: classify_compute,
    title: '专业电脑办公管理',
    content:
        '电脑办公行业为企业提供日常办公所必须的用品，<br/> 包括电脑、打印机等电子产品以及纸笔桌椅等办公用品。',
    list1: [
        {
            imgUrl: blue_icon1,
            describe: '更新迭代快，型号品类多，货品管理困难',
            rgba: '230, 242, 240, 0.5'
        },
        {
            imgUrl: blue_icon2,
            describe: '电脑类货品价格变动快，价格维护困难',
            rgba: '230, 242, 240, 0.5'
        },
        {
            imgUrl: blue_icon3,
            describe: '小家电品类多，销售量大，需要快速完成开单',
            rgba: '230, 242, 240, 0.5'
        },
        {
            imgUrl: blue_icon4,
            describe: '采购量大，资金管控难，需严格审批',
            rgba: '230, 242, 240, 0.5'
        },
        {
            imgUrl: blue_icon5,
            describe: '电脑办公用品涉及的范围较广，需要从不同的供应商采购，要对供应商进行管理。',
            rgba: '230, 242, 240, 0.5'
        }
    ],
    list2: [
        {
            name: '货品',
            describe: '货品分类管理'
        },
        {
            name: '策略',
            describe: '价格策略管理'
        },
        {
            name: '提醒',
            describe: '预交日期提醒'
        },
        {
            name: '供应商',
            describe: '供应商价格策略'
        },
        {
            name: '下单',
            describe: '移动端快速下单'
        },
        {
            name: '储位',
            describe: '仓库储位精细化管理'
        }
    ],
    list3: [
        {
            imgUrl: screenshot_compute1,
            name: '货品分类管理'
        },
        {
            imgUrl: screenshot_compute2,
            name: '价格策略管理'
        },
        {
            imgUrl: screenshot_compute3,
            name: '审批流程管理'
        },
        {
            imgUrl: screenshot_compute4,
            name: '供应商管理'
        },
        {
            imgUrl: screenshot_compute5,
            name: '审批流设置.'
        },
        {
            imgUrl: screenshot_compute6,
            name: '移动端下单'
        }
    ],
    list4: [
        {
            imgUrl: logo27,
            name: 'Homkie'
        },
        {
            imgUrl: logo13,
            name: '虎山电子'
        },
        {
            imgUrl: logo9,
            name: '全朗'
        },
        {
            imgUrl: logo14,
            name: '金炬实业'
        },
        {
            imgUrl: logo30,
            name: '羽珀'
        },
        {
            imgUrl: logo8,
            name: 'Hightech'
        }
    ]
};

export default compute;
