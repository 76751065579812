import electric_bg from '../../images/bg/electric.png';
import green_icon1 from '../../images/challenge/g1.png';
import green_icon2 from '../../images/challenge/g2.png';
import green_icon3 from '../../images/challenge/g3.png';
import green_icon4 from '../../images/challenge/g4.png';
import green_icon5 from '../../images/challenge/g5.png';
import enterprise_electric1 from '../../images/enterprise-logo/electric/electric1.png';
import enterprise_electric2 from '../../images/enterprise-logo/electric/electric2.png';
import enterprise_electric3 from '../../images/enterprise-logo/electric/electric3.png';
import enterprise_electric4 from '../../images/enterprise-logo/electric/electric4.png';
import enterprise_electric5 from '../../images/enterprise-logo/electric/electric5.png';
import enterprise_electric6 from '../../images/enterprise-logo/electric/electric6.png';
import classify_electric from '../../images/industry/electric1.png';
import screenshot_electric1 from '../../images/screenshot/electric/electric1.png';
import screenshot_electric2 from '../../images/screenshot/electric/electric2.png';
import screenshot_electric3 from '../../images/screenshot/electric/electric3.png';
import screenshot_electric4 from '../../images/screenshot/electric/electric4.png';
import screenshot_electric5 from '../../images/screenshot/electric/electric5.png';
import screenshot_electric6 from '../../images/screenshot/electric/electric6.png';

const electric = {
    bgUrl: electric_bg,
    classifyIcon: classify_electric,
    title: '专业电气电工管理',
    content:
        '电气电工主要指高低压电器、高低压成套开关设备和控制设备、<br/> 无功补偿与电能质量、节能技术、智能电网、新能源技术、微电网等相关产业。',
    list1: [
        {
            imgUrl: green_icon1,
            describe: '电气电工行业品类多，型号多，规模大，货品管理困难。',
            rgba: '205, 239, 250, 0.5'
        },
        {
            imgUrl: green_icon2,
            describe: '订单交付周期长，一般要先采购再交付。其次，销售单多、管理难度大。',
            rgba: '205, 239, 250, 0.5'
        },
        {
            imgUrl: green_icon3,
            describe: '项目中交付货品多，又分多次、对账困难。',
            rgba: '205, 239, 250, 0.5'
        },
        {
            imgUrl: green_icon4,
            describe:
                '客户经常会二次、三次补货，手工帐目太多，找不到上次销售价和优惠折扣，影响销售。',
            rgba: '205, 239, 250, 0.5'
        },
        {
            imgUrl: green_icon5,
            describe: '电气货品更新换代快，货源难找，导致库存积压多。',
            rgba: '205, 239, 250, 0.5'
        }
    ],
    list2: [
        {
            name: '货品',
            describe: '货品分类管理'
        },
        {
            name: '策略',
            describe: '价格策略管理'
        },
        {
            name: '提醒',
            describe: '预交日期提醒'
        },
        {
            name: '供应商',
            describe: '供应商价格策略'
        },
        {
            name: '下单',
            describe: '移动端快速下单'
        },
        {
            name: '储位',
            describe: '仓库储位精细化管理'
        }
    ],
    list3: [
        {
            imgUrl: screenshot_electric1,
            name: '货品分类管理'
        },
        {
            imgUrl: screenshot_electric2,
            name: '预付账款管理'
        },
        {
            imgUrl: screenshot_electric3,
            name: '应付账款统一核销'
        },
        {
            imgUrl: screenshot_electric4,
            name: '历史折扣记录'
        },
        {
            imgUrl: screenshot_electric5,
            name: '库存预警'
        },
        {
            imgUrl: screenshot_electric6,
            name: '审批流设置'
        }
    ],
    list4: [
        {
            imgUrl: enterprise_electric1,
            name: '智能充电'
        },
        {
            imgUrl: enterprise_electric2,
            name: '上海西科仕电气'
        },
        {
            imgUrl: enterprise_electric3,
            name: '雷霆电气'
        },
        {
            imgUrl: enterprise_electric4,
            name: '钱鸥电气'
        },
        {
            imgUrl: enterprise_electric5,
            name: 'SAS'
        },
        {
            imgUrl: enterprise_electric6,
            name: 'CI'
        }
    ]
};

export default electric;
