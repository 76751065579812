import React from 'react';

import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import Footer from '../components/footer';
import Header from '../components/header';
import Content from '../components/content';
import SEO from '../components/seo';
// import Icon from '../components/icon';
import Analytics from '../components/analytics';

import detailDataMap from '../store/industryDetail';

import industryDetailStyle from '../styles/industry-detail.module.less';

export default () => {
    const type = typeof window !== `undefined` ? window.location.hash.replace('#', '') : 'phone';

    const imageData = useStaticQuery(graphql`
        query {
            qrCodeImg: file(relativePath: { eq: "QRCode1.png" }) {
                childImageSharp {
                    fixed(height: 140) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `);

    const detail = detailDataMap[type];
    // console.log(detail);
    return (
        <section>
            <SEO title="行业" />
            <Header />
            <div className={industryDetailStyle.content}>
                <img src={detail.bgUrl} alt="背景"/>
                <Content className={industryDetailStyle.industryList}>
                    {/* <div
                        className={industryDetailStyle.industryList__bg}
                        style={{ background: `url(${detail.bgUrl}) no-repeat` }}
                    ></div> */}
                    <div className={industryDetailStyle.industryDetail}>
                        <h3>{detail.title}</h3>
                        {/* <p>{detail.content}</p> */}
                        <p dangerouslySetInnerHTML={{__html: detail.content}}></p>
                        <div className={industryDetailStyle.txtCenter}>
                            <img src={detail.classifyIcon} alt="icon"/>
                        </div>
                        <h3>问题与挑战</h3>
                        <h4>基于您的行业，提供贴心的服务</h4>
                        <ul className={industryDetailStyle.industryDetail__lists}>
                            {detail.list1.map((value, index) => (
                                <li style={{ background: `rgba(${value.rgba})` }} key={index}>
                                    <img src={value.imgUrl} alt={value.describe}/>
                                    <span>{value.describe}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                </Content>
                <div style={{ backgroundColor: 'rgba(250, 239, 218, 0.29)' }}>
                    <Content className={industryDetailStyle.industryScheme}>
                        <h3>我们的解决方案</h3>
                        <h4>个性化定制，问题逐个击破</h4>
                        <ul>
                            {detail.list2.map((value, index) => (
                                <li key={index}>
                                    <span>{value.name}</span>
                                    <p>{value.describe}</p>
                                </li>
                            ))}
                        </ul>
                    </Content>
                </div>
                <div style={{ backgroundColor: '#f8f8f9' }}>
                    <Content className={industryDetailStyle.industryModules}>
                        <h3>功能截图</h3>
                        <h4>简洁界面，一目了然</h4>
                        <ul>
                            {detail.list3.map((value, index) => (
                                <li key={index}>
                                    <img src={value.imgUrl} alt={value.name} />
                                    <p>{value.name}</p>
                                </li>
                            ))}
                        </ul>
                    </Content>
                </div>

                <Content className={industryDetailStyle.industryAllUse}>
                    <h3>他们都在用</h3>
                    <h4>优秀企业的选择，就是你的选择</h4>
                    <ul>
                        {detail.list4.map((value, index) => (
                            <li key={index}>
                                <img src={value.imgUrl} alt={value.name} />
                            </li>
                        ))}
                    </ul>
                </Content>
                <Content className={industryDetailStyle.industryApp}>
                    <h3>立即下载简库存 做轻松的老板</h3>
                    <Img fixed={imageData.qrCodeImg.childImageSharp.fixed} alt="二维码"/>
                </Content>
            </div>
            <Analytics />
            <Footer />
        </section>
    );
};
