import beauty_bg from '../../images/bg/beauty.png';
import pink_icon1 from '../../images/challenge/p1.png';
import pink_icon2 from '../../images/challenge/p2.png';
import pink_icon3 from '../../images/challenge/p3.png';
import pink_icon4 from '../../images/challenge/p4.png';
import pink_icon5 from '../../images/challenge/p5.png';
import enterprise_beauty1 from '../../images/enterprise-logo/beauty/beauty1.png';
import enterprise_beauty2 from '../../images/enterprise-logo/beauty/beauty2.png';
import enterprise_beauty3 from '../../images/enterprise-logo/beauty/beauty3.png';
import enterprise_beauty4 from '../../images/enterprise-logo/beauty/beauty4.png';
import enterprise_beauty5 from '../../images/enterprise-logo/beauty/beauty5.png';
import enterprise_beauty6 from '../../images/enterprise-logo/beauty/beauty6.png';
import classify_beauty from '../../images/industry/beauty1.png';
import screenshot_beauty1 from '../../images/screenshot/beauty/beauty1.png';
import screenshot_beauty2 from '../../images/screenshot/beauty/beauty2.png';
import screenshot_beauty3 from '../../images/screenshot/beauty/beauty3.png';
import screenshot_beauty4 from '../../images/screenshot/beauty/beauty4.png';
import screenshot_beauty5 from '../../images/screenshot/beauty/beauty5.png';
import screenshot_beauty6 from '../../images/screenshot/beauty/beauty6.png';

const beauty = {
    bgUrl: beauty_bg,
    classifyIcon: classify_beauty,
    title: '专业美容化妆管理',
    content:
        '美容化妆需求高。中国化妆品市场规模大且广，<br/> 且整体化妆品消费的市场规模将持续稳健增长，行业竞争也日益激烈。',
    list1: [
        {
            imgUrl: pink_icon1,
            describe: '美容化妆货品种类多、品牌多，功能多，货品管理困难。',
            rgba: '252, 230, 231, 0.5'
        },
        {
            imgUrl: pink_icon2,
            describe:
                '客户需求量大，货品销售快，订单多，客户需要看货下单，使用手机随时随地的进行下单。',
            rgba: '252, 230, 231, 0.5'
        },
        {
            imgUrl: pink_icon3,
            describe:
                '美容化妆行业的保质期管理尤为重要，不同批次的货品过期时间不一样，要做到销售时先入库的货品先销售出库。',
            rgba: '252, 230, 231, 0.5'
        },
        {
            imgUrl: pink_icon4,
            describe: '中高档美容化妆品的消费群体扩大，货品销量增高，部分货品销量低，购买差异大。',
            rgba: '252, 230, 231, 0.5'
        },
        {
            imgUrl: pink_icon5,
            describe: '货品种类多，日成交量大，出入库需要有详细的记录。',
            rgba: '252, 230, 231, 0.5'
        }
    ],
    list2: [
        {
            name: '货品',
            describe: '货品分类管理'
        },
        {
            name: '策略',
            describe: '价格策略管理'
        },
        {
            name: '提醒',
            describe: '预交日期提醒'
        },
        {
            name: '供应商',
            describe: '供应商价格策略'
        },
        {
            name: '下单',
            describe: '移动端快速下单'
        },
        {
            name: '储位',
            describe: '仓库储位精细化管理'
        }
    ],
    list3: [
        {
            imgUrl: screenshot_beauty1,
            name: '货品分类管理'
        },
        {
            imgUrl: screenshot_beauty2,
            name: '批次货品管理'
        },
        {
            imgUrl: screenshot_beauty3,
            name: '货品销量排行'
        },
        {
            imgUrl: screenshot_beauty4,
            name: '货品管理'
        },
        {
            imgUrl: screenshot_beauty5,
            name: '出入库明细表'
        },
        {
            imgUrl: screenshot_beauty6,
            name: '移动端下单'
        }
    ],
    list4: [
        {
            imgUrl: enterprise_beauty1,
            name: '艾杜'
        },
        {
            imgUrl: enterprise_beauty2,
            name: 'sirna'
        },
        {
            imgUrl: enterprise_beauty3,
            name: 'L'
        },
        {
            imgUrl: enterprise_beauty4,
            name: '润利国际'
        },
        {
            imgUrl: enterprise_beauty5,
            name: '美迹美妆'
        },
        {
            imgUrl: enterprise_beauty6,
            name: '蜜思'
        }
    ]
};

export default beauty;