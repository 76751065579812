import metals_bg from '../../images/bg/metals.jpg';
import blue_icon1 from '../../images/challenge/b1.png';
import blue_icon2 from '../../images/challenge/b2.png';
import blue_icon3 from '../../images/challenge/b3.png';
import blue_icon4 from '../../images/challenge/b4.png';
import blue_icon5 from '../../images/challenge/b5.png';
import logo13 from '../../images/enterprise-logo/logo/logo13.png';
import logo14 from '../../images/enterprise-logo/logo/logo14.png';
import logo23 from '../../images/enterprise-logo/logo/logo23.png';
import logo29 from '../../images/enterprise-logo/logo/logo29.png';
import logo4 from '../../images/enterprise-logo/logo/logo4.png';
import logo9 from '../../images/enterprise-logo/logo/logo9.png';
import classify_metals from '../../images/industry/metals1.png';
import screenshot_metals1 from '../../images/screenshot/metals/metals1.png';
import screenshot_metals2 from '../../images/screenshot/metals/metals2.png';
import screenshot_metals3 from '../../images/screenshot/metals/metals3.png';
import screenshot_metals4 from '../../images/screenshot/metals/metals4.png';
import screenshot_metals5 from '../../images/screenshot/metals/metals5.png';
import screenshot_metals6 from '../../images/screenshot/metals/metals6.png';

const metals = {
    bgUrl: metals_bg,
    classifyIcon: classify_metals,
    title: '专业五金机械管理',
    content:
        '五金制品行业发展迅猛，其中尤其以机械五金板块发展速度极快。<br/> 五金制品是工业制造中不可缺少的一个产业环节。',
    list1: [
        {
            imgUrl: blue_icon1,
            describe: '五金机械产品品类多、 产品零散、管理难度大。',
            rgba: '225, 240, 253, 0.5'
        },
        {
            imgUrl: blue_icon2,
            describe: '订单交付周期短，需要快速采购、备货便于完成交付。',
            rgba: '225, 240, 253, 0.5'
        },
        {
            imgUrl: blue_icon3,
            describe: '供应渠道多，不同产品的供应商有不同的优惠政策，才能保持长久的合作。',
            rgba: '225, 240, 253, 0.5'
        },
        {
            imgUrl: blue_icon4,
            describe: '销售人员在外需要实时查询库存，方便销售开单。',
            rgba: '225, 240, 253, 0.5'
        },
        {
            imgUrl: blue_icon5,
            describe: '产品零散，库存存放杂乱无章。',
            rgba: '225, 240, 253, 0.5'
        }
    ],
    list2: [
        {
            name: '货品',
            describe: '货品分类管理'
        },
        {
            name: '录入',
            describe: '快速录入单据'
        },
        {
            name: '提醒',
            describe: '预交日期提醒'
        },
        {
            name: '供应商',
            describe: '供应商价格策略'
        },
        {
            name: '下单',
            describe: '移动端快速下单'
        },
        {
            name: '储位',
            describe: '仓库储位精细化管理'
        }
    ],
    list3: [
        {
            imgUrl: screenshot_metals1,
            name: '仓库储位精细化管理'
        },
        {
            imgUrl: screenshot_metals2,
            name: '订单超期预警'
        },
        {
            imgUrl: screenshot_metals3,
            name: '供应商价格策略'
        },
        {
            imgUrl: screenshot_metals4,
            name: '货品分类管理'
        },
        {
            imgUrl: screenshot_metals5,
            name: '快速录入单据'
        },
        {
            imgUrl: screenshot_metals6,
            name: '手机快速下单'
        }
    ],
    list4: [
        {
            imgUrl: logo23,
            name: '恩特机械'
        },
        {
            imgUrl: logo13,
            name: '虎山电子'
        },
        {
            imgUrl: logo9,
            name: '全朗'
        },
        {
            imgUrl: logo14,
            name: '金炬实业'
        },
        {
            imgUrl: logo29,
            name: 'IZIT'
        },
        {
            imgUrl: logo4,
            name: '中移铁通'
        }
    ]
};

export default metals;
