import goods_bg from '../../images/bg/goods.png';
import blue_icon1 from '../../images/challenge/b1.png';
import blue_icon2 from '../../images/challenge/b2.png';
import blue_icon3 from '../../images/challenge/b3.png';
import blue_icon4 from '../../images/challenge/b4.png';
import blue_icon5 from '../../images/challenge/b5.png';
import enterprise_goods1 from '../../images/enterprise-logo/goods/goods1.png';
import enterprise_goods2 from '../../images/enterprise-logo/goods/goods2.png';
import enterprise_goods3 from '../../images/enterprise-logo/goods/goods3.png';
import enterprise_goods4 from '../../images/enterprise-logo/goods/goods4.png';
import enterprise_goods5 from '../../images/enterprise-logo/goods/goods5.png';
import enterprise_goods6 from '../../images/enterprise-logo/goods/goods6.png';
import classify_goods from '../../images/industry/goods1.png';
import screenshot_goods1 from '../../images/screenshot/goods/goods1.png';
import screenshot_goods2 from '../../images/screenshot/goods/goods2.png';
import screenshot_goods3 from '../../images/screenshot/goods/goods3.png';
import screenshot_goods4 from '../../images/screenshot/goods/goods4.png';
import screenshot_goods5 from '../../images/screenshot/goods/goods5.png';
import screenshot_goods6 from '../../images/screenshot/goods/goods6.png';

const goods = {
    bgUrl: goods_bg,
    classifyIcon: classify_goods,
    title: '专业日用百货管理',
    content:
        '日用百货类商品是日用生活必需品的主要部分。<br/> 日用百货类商品，花样质量不断更新换代，品种齐全、质量好，数量充足，满足广大群众的需要。',
    list1: [
        {
            imgUrl: blue_icon1,
            describe:
                '百货品类多、品牌多、类别多、货品管理难度大，为了快速开单，还需要良好的条码管理。',
            rgba: '230, 242, 240, 0.5'
        },
        {
            imgUrl: blue_icon2,
            describe: '在销售中，客户多，订单多，购买的货品多，需要快速完成销售开单。',
            rgba: '230, 242, 240, 0.5'
        },
        {
            imgUrl: blue_icon3,
            describe:
                '货品标准化高，客户流动性大，需要给客户提供专属的优惠折扣，才能长期吸引客户。',
            rgba: '230, 242, 240, 0.5'
        },
        {
            imgUrl: blue_icon4,
            describe: '货品品类多，库存大，库存盘点工作量巨大。',
            rgba: '230, 242, 240, 0.5'
        },
        {
            imgUrl: blue_icon5,
            describe: '客户流动性大，需求量高，需有效控制商品的进货，避免商品短缺或重复进货。',
            rgba: '230, 242, 240, 0.5'
        }
    ],
    list2: [
        {
            name: '货品',
            describe: '货品分类管理'
        },
        {
            name: '策略',
            describe: '价格策略管理'
        },
        {
            name: '提醒',
            describe: '预交日期提醒'
        },
        {
            name: '供应商',
            describe: '供应商价格策略'
        },
        {
            name: '下单',
            describe: '移动端快速下单'
        },
        {
            name: '储位',
            describe: '仓库储位精细化管理'
        }
    ],
    list3: [
        {
            imgUrl: screenshot_goods1,
            name: '货品分类管理'
        },
        {
            imgUrl: screenshot_goods2,
            name: '价格策略管理'
        },
        {
            imgUrl: screenshot_goods3,
            name: '库存预警'
        },
        {
            imgUrl: screenshot_goods4,
            name: '账款报表实时查询'
        },
        {
            imgUrl: screenshot_goods5,
            name: '移动端下单'
        },
        {
            imgUrl: screenshot_goods6,
            name: '移动端盘点'
        }
    ],
    list4: [
        {
            imgUrl: enterprise_goods1,
            name: '意可可'
        },
        {
            imgUrl: enterprise_goods2,
            name: '上海品微堂'
        },
        {
            imgUrl: enterprise_goods3,
            name: '卡柏洗衣'
        },
        {
            imgUrl: enterprise_goods4,
            name: '草莓生活'
        },
        {
            imgUrl: enterprise_goods5,
            name: 'HKHP'
        },
        {
            imgUrl: enterprise_goods6,
            name: '狄腾马'
        }
    ]
};

export default goods;
