import weave_bg from '../../images/bg/weave.png';
import blue_icon1 from '../../images/challenge/b1.png';
import blue_icon2 from '../../images/challenge/b2.png';
import blue_icon3 from '../../images/challenge/b3.png';
import blue_icon4 from '../../images/challenge/b4.png';
import blue_icon5 from '../../images/challenge/b5.png';
import enterprise_weave1 from '../../images/enterprise-logo/weave/weave1.png';
import enterprise_weave2 from '../../images/enterprise-logo/weave/weave2.png';
import enterprise_weave3 from '../../images/enterprise-logo/weave/weave3.png';
import enterprise_weave4 from '../../images/enterprise-logo/weave/weave4.png';
import enterprise_weave5 from '../../images/enterprise-logo/weave/weave5.png';
import enterprise_weave6 from '../../images/enterprise-logo/weave/weave6.png';
import classify_weave from '../../images/industry/weave1.png';
import screenshot_weave1 from '../../images/screenshot/weave/weave1.png';
import screenshot_weave2 from '../../images/screenshot/weave/weave2.png';
import screenshot_weave3 from '../../images/screenshot/weave/weave3.png';
import screenshot_weave4 from '../../images/screenshot/weave/weave4.png';
import screenshot_weave5 from '../../images/screenshot/weave/weave5.png';
import screenshot_weave6 from '../../images/screenshot/weave/weave6.png';

const weave = {
    bgUrl: weave_bg,
    classifyIcon: classify_weave,
    title: '专业纺织皮革管理',
    content:
        '纺织业在中国是一个劳动密集程度高和对外依存度较大的产业。<br/> 中国是世界上最大的纺织品服装生产和出口国。',
    list1: [
        {
            imgUrl: blue_icon1,
            describe: '纺织皮革品类多、规格多、不标准、货品数量庞大，货品管理极为困难。',
            rgba: '230, 242, 240, 0.5'
        },
        {
            imgUrl: blue_icon2,
            describe:
                '纺织皮革货品经常没有现货，按客户需求去采购、订货， 没有良好的客户跟踪系统，就会出现漏采、或者交付不及时等问题。',
            rgba: '230, 242, 240, 0.5'
        },
        {
            imgUrl: blue_icon3,
            describe:
                '现货销售时，时需要经常查同款号，不同颜色、材质的库存量，不能快速查询就会影响销售。',
            rgba: '230, 242, 240, 0.5'
        },
        {
            imgUrl: blue_icon4,
            describe: '批发销售与采购金额大，款项出入多，容易形成财务漏洞，损失巨大。',
            rgba: '230, 242, 240, 0.5'
        },
        {
            imgUrl: blue_icon5,
            describe: '按平方米销售，按大件出库， 出库单位与销售单位不同。',
            rgba: '230, 242, 240, 0.5'
        }
    ],
    list2: [
        {
            name: '货品',
            describe: '货品分类管理'
        },
        {
            name: '策略',
            describe: '价格策略管理'
        },
        {
            name: '提醒',
            describe: '预交日期提醒'
        },
        {
            name: '供应商',
            describe: '供应商价格策略'
        },
        {
            name: '下单',
            describe: '移动端快速下单'
        },
        {
            name: '储位',
            describe: '仓库储位精细化管理'
        }
    ],
    list3: [
        {
            imgUrl: screenshot_weave1,
            name: '货品分类管理'
        },
        {
            imgUrl: screenshot_weave2,
            name: '账款管理'
        },
        {
            imgUrl: screenshot_weave3,
            name: '客户管理'
        },
        {
            imgUrl: screenshot_weave4,
            name: '多单位管理'
        },
        {
            imgUrl: screenshot_weave5,
            name: '账款报表实时查询'
        },
        {
            imgUrl: screenshot_weave6,
            name: '移动端查询库存'
        }
    ],
    list4: [
        {
            imgUrl: enterprise_weave1,
            name: '恩特机械'
        },
        {
            imgUrl: enterprise_weave2,
            name: '狄腾马'
        },
        {
            imgUrl: enterprise_weave3,
            name: '洪恩毛纺'
        },
        {
            imgUrl: enterprise_weave4,
            name: '有诚纺织'
        },
        {
            imgUrl: enterprise_weave5,
            name: '纤资皇'
        },
        {
            imgUrl: enterprise_weave6,
            name: 'SLLKS'
        }
    ]
};

export default weave;
