import toy_bg from '../../images/bg/toy.png';
import pink_icon1 from '../../images/challenge/p1.png';
import pink_icon2 from '../../images/challenge/p2.png';
import pink_icon3 from '../../images/challenge/p3.png';
import pink_icon4 from '../../images/challenge/p4.png';
import pink_icon5 from '../../images/challenge/p5.png';
import enterprise_toy1 from '../../images/enterprise-logo/toy/toy1.png';
import enterprise_toy2 from '../../images/enterprise-logo/toy/toy2.png';
import enterprise_toy3 from '../../images/enterprise-logo/toy/toy3.png';
import enterprise_toy4 from '../../images/enterprise-logo/toy/toy4.png';
import enterprise_toy5 from '../../images/enterprise-logo/toy/toy5.png';
import enterprise_toy6 from '../../images/enterprise-logo/toy/toy6.png';
import classify_toy from '../../images/industry/toy1.png';
import screenshot_toy1 from '../../images/screenshot/toy/toy1.png';
import screenshot_toy2 from '../../images/screenshot/toy/toy2.png';
import screenshot_toy3 from '../../images/screenshot/toy/toy3.png';
import screenshot_toy4 from '../../images/screenshot/toy/toy4.png';
import screenshot_toy5 from '../../images/screenshot/toy/toy5.png';
import screenshot_toy6 from '../../images/screenshot/toy/toy6.png';

const toy = {
    bgUrl: toy_bg,
    classifyIcon: classify_toy,
    title: '专业母婴玩具管理',
    content:
        '母婴用品是指为孕产期女性与0-3岁婴儿这两类特殊相关<br/> 联群体提供的专业健康产品。母婴这两类特殊相关联群体提供的专业健康产品。',
    list1: [
        {
            imgUrl: pink_icon1,
            describe: '销售母婴货品的业务员经常会驻点医院，要随时开单、回款、配货，完成销售。',
            rgba: '252, 230, 231, 0.5'
        },
        {
            imgUrl: pink_icon2,
            describe: '母婴玩具货品销售主要靠熟客，需要有良好的VIP管理系统，提供专属的优惠折扣等',
            rgba: '252, 230, 231, 0.5'
        },
        {
            imgUrl: pink_icon3,
            describe: '母婴货品的品种类目较多，品牌多，货品管理难度大',
            rgba: '252, 230, 231, 0.5'
        },
        {
            imgUrl: pink_icon4,
            describe: '母婴行业货品种类多，日成交量大，出入库需要有详细的记录。',
            rgba: '252, 230, 231, 0.5'
        },
        {
            imgUrl: pink_icon5,
            describe: '连锁门店之间需要查询库存，及时调货，才能完成销售。',
            rgba: '252, 230, 231, 0.5'
        }
    ],
    list2: [
        {
            name: '货品',
            describe: '货品分类管理'
        },
        {
            name: '策略',
            describe: '价格策略管理'
        },
        {
            name: '提醒',
            describe: '预交日期提醒'
        },
        {
            name: '供应商',
            describe: '供应商价格策略'
        },
        {
            name: '下单',
            describe: '移动端快速下单'
        },
        {
            name: '储位',
            describe: '仓库储位精细化管理'
        }
    ],
    list3: [
        {
            imgUrl: screenshot_toy1,
            name: '价格策略管理'
        },
        {
            imgUrl: screenshot_toy2,
            name: '货品分类管理'
        },
        {
            imgUrl: screenshot_toy3,
            name: '出入库类报表'
        },
        {
            imgUrl: screenshot_toy4,
            name: '库存调拨'
        },
        {
            imgUrl: screenshot_toy5,
            name: '账款报表实时查询'
        },
        {
            imgUrl: screenshot_toy6,
            name: '移动端下单'
        }
    ],
    list4: [
        {
            imgUrl: enterprise_toy1,
            name: 'mama&baby'
        },
        {
            imgUrl: enterprise_toy2,
            name: '科莱尔'
        },
        {
            imgUrl: enterprise_toy3,
            name: 'AYBL'
        },
        {
            imgUrl: enterprise_toy4,
            name: '怡思'
        },
        {
            imgUrl: enterprise_toy5,
            name: '玩乐王'
        },
        {
            imgUrl: enterprise_toy6,
            name: '婴之恋'
        }
    ]
};

export default toy;
