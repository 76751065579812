import handicraft_bg from '../../images/bg/handicraft.png';
import green_icon1 from '../../images/challenge/g1.png';
import green_icon2 from '../../images/challenge/g2.png';
import green_icon3 from '../../images/challenge/g3.png';
import green_icon4 from '../../images/challenge/g4.png';
import green_icon5 from '../../images/challenge/g5.png';
import enterprise_handicraft1 from '../../images/enterprise-logo/handicraft/handicraft1.png';
import enterprise_handicraft2 from '../../images/enterprise-logo/handicraft/handicraft2.png';
import enterprise_handicraft3 from '../../images/enterprise-logo/handicraft/handicraft3.png';
import enterprise_handicraft4 from '../../images/enterprise-logo/handicraft/handicraft4.png';
import enterprise_handicraft5 from '../../images/enterprise-logo/handicraft/handicraft5.png';
import enterprise_handicraft6 from '../../images/enterprise-logo/handicraft/handicraft6.png';
import classify_handicraft from '../../images/industry/handicraft1.png';
import screenshot_handicraft1 from '../../images/screenshot/handicraft/handicraft1.png';
import screenshot_handicraft2 from '../../images/screenshot/handicraft/handicraft2.png';
import screenshot_handicraft3 from '../../images/screenshot/handicraft/handicraft3.png';
import screenshot_handicraft4 from '../../images/screenshot/handicraft/handicraft4.png';
import screenshot_handicraft5 from '../../images/screenshot/handicraft/handicraft5.png';
import screenshot_handicraft6 from '../../images/screenshot/handicraft/handicraft6.png';

const handicraft = {
    bgUrl: handicraft_bg,
    classifyIcon: classify_handicraft,
    title: '专业手工艺品管理',
    content:
        '手工艺品，是指民间的劳动人民为适应生活需要和审美要求，就地取材，<br/> 以手工生产为主的一种工艺美术品。手工艺品的品种非常繁多，<br/>如宋锦、竹编、草编、手工刺绣、蓝印花布、蜡染、手工木雕、泥塑、剪纸、民间玩具等。',
    list1: [
        {
            imgUrl: green_icon1,
            describe: '手工艺品种类多，风格多，更新快，货品管理困难。',
            rgba: '205, 239, 250, 0.5'
        },
        {
            imgUrl: green_icon2,
            describe:
                '手工艺品的运营状况至关重要，需要确实掌握商品实有库存数量及其金额，进行全部或部分清点。',
            rgba: '205, 239, 250, 0.5'
        },
        {
            imgUrl: green_icon3,
            describe: '有些手工艺品易碎，配送过程中会出现货品损坏，客户投诉多，申请退货的现象多。',
            rgba: '205, 239, 250, 0.5'
        },
        {
            imgUrl: green_icon4,
            describe: '手工艺品因为工艺繁琐，人力需求大，成本的核算复杂。',
            rgba: '205, 239, 250, 0.5'
        },
        {
            imgUrl: green_icon5,
            describe: '有些工艺品制作加工流程复杂，耗费时间长，客户会先预付一部分的定金。',
            rgba: '205, 239, 250, 0.5'
        }
    ],
    list2: [
        {
            name: '货品',
            describe: '货品分类管理'
        },
        {
            name: '策略',
            describe: '价格策略管理'
        },
        {
            name: '提醒',
            describe: '预交日期提醒'
        },
        {
            name: '供应商',
            describe: '供应商价格策略'
        },
        {
            name: '下单',
            describe: '移动端快速下单'
        },
        {
            name: '储位',
            describe: '仓库储位精细化管理'
        }
    ],
    list3: [
        {
            imgUrl: screenshot_handicraft1,
            name: '货品分类管理'
        },
        {
            imgUrl: screenshot_handicraft2,
            name: '库存盘点'
        },
        {
            imgUrl: screenshot_handicraft3,
            name: '销售退货'
        },
        {
            imgUrl: screenshot_handicraft4,
            name: '成本核算'
        },
        {
            imgUrl: screenshot_handicraft5,
            name: '预付账款管理'
        },
        {
            imgUrl: screenshot_handicraft6,
            name: '出入库明细表'
        }
    ],
    list4: [
        {
            imgUrl: enterprise_handicraft1,
            name: '上海品微堂'
        },
        {
            imgUrl: enterprise_handicraft2,
            name: 'JINSHAO'
        },
        {
            imgUrl: enterprise_handicraft3,
            name: '全朗'
        },
        {
            imgUrl: enterprise_handicraft4,
            name: 'CLOUD'
        },
        {
            imgUrl: enterprise_handicraft5,
            name: '瑞森纳'
        },
        {
            imgUrl: enterprise_handicraft6,
            name: '皮具'
        }
    ]
};

export default handicraft;
