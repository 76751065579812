import pattern_bg from '../../images/bg/pattern.png';
import blue_icon1 from '../../images/challenge/b1.png';
import blue_icon2 from '../../images/challenge/b2.png';
import blue_icon3 from '../../images/challenge/b3.png';
import blue_icon4 from '../../images/challenge/b4.png';
import blue_icon5 from '../../images/challenge/b5.png';
import logo14 from '../../images/enterprise-logo/logo/logo14.png';
import logo23 from '../../images/enterprise-logo/logo/logo23.png';
import logo27 from '../../images/enterprise-logo/logo/logo27.png';
import logo29 from '../../images/enterprise-logo/logo/logo29.png';
import logo7 from '../../images/enterprise-logo/logo/logo7.png';
import logo9 from '../../images/enterprise-logo/logo/logo9.png';
import classify_pattern from '../../images/industry/pattern1.png';
import screenshot_pattern1 from '../../images/screenshot/pattern/pattern1.png';
import screenshot_pattern2 from '../../images/screenshot/pattern/pattern2.png';
import screenshot_pattern3 from '../../images/screenshot/pattern/pattern3.png';
import screenshot_pattern4 from '../../images/screenshot/pattern/pattern4.png';
import screenshot_pattern5 from '../../images/screenshot/pattern/pattern5.png';
import screenshot_pattern6 from '../../images/screenshot/pattern/pattern6.png';

const pattern = {
    bgUrl: pattern_bg,
    classifyIcon: classify_pattern,
    title: '专业模具钢材管理',
    content:
        '模具钢材是用来制造冷冲模、热锻模压铸模等模具的钢种。<br/> 近年来，国内外模具钢材的产量、生产技术、工艺装备、质量、品种等方面都取得了比较迅速的发展。',
    list1: [
        {
            imgUrl: blue_icon1,
            describe: '钢材模具品类多、规格多、不标准、货品数量庞大，货品管理极为困难。',
            rgba: '225, 240, 253, 0.5'
        },
        {
            imgUrl: blue_icon2,
            describe: '批发销售与采购金额大，款项出入多，容易形成财务漏洞，损失巨大。',
            rgba: '225, 240, 253, 0.5'
        },
        {
            imgUrl: blue_icon3,
            describe:
                '磨具钢材货品经常没有现货，按客户需求去采购、订货，没有良好的客户跟踪系统，就会出现漏采、或者交付不及时等问题。',
            rgba: '225, 240, 253, 0.5'
        },
        {
            imgUrl: blue_icon4,
            describe:
                '现货销售时，时需要经常查同款货品，不同规格的库存量，不能快速查询就会影响销售。',
            rgba: '225, 240, 253, 0.5'
        },
        {
            imgUrl: blue_icon5,
            describe: '按平方米销售，按大件（张、卷）出库， 出库单位与销售单位不同。',
            rgba: '225, 240, 253, 0.5'
        }
    ],
    list2: [
        {
            name: '货品',
            describe: '货品分类管理'
        },
        {
            name: '策略',
            describe: '价格策略管理'
        },
        {
            name: '管理',
            describe: '多单位管理'
        },
        {
            name: '供应商',
            describe: '供应商价格策略'
        },
        {
            name: '下单',
            describe: '移动端快速下单'
        },
        {
            name: '储位',
            describe: '仓库储位精细化管理'
        }
    ],
    list3: [
        {
            imgUrl: screenshot_pattern1,
            name: '货品分类管理'
        },
        {
            imgUrl: screenshot_pattern2,
            name: '账款管理'
        },
        {
            imgUrl: screenshot_pattern3,
            name: '客户管理'
        },
        {
            imgUrl: screenshot_pattern4,
            name: '多单位管理'
        },
        {
            imgUrl: screenshot_pattern5,
            name: '账款报表实时查询'
        },
        {
            imgUrl: screenshot_pattern6,
            name: '移动端查询库存'
        }
    ],
    list4: [
        {
            imgUrl: logo23,
            name: '恩特机械'
        },
        {
            imgUrl: logo27,
            name: '恩际'
        },
        {
            imgUrl: logo9,
            name: '全朗'
        },
        {
            imgUrl: logo14,
            name: '金炬实业'
        },
        {
            imgUrl: logo29,
            name: 'IZIT'
        },
        {
            imgUrl: logo7,
            name: 'DOLSI'
        }
    ]
};

export default pattern;