import instrument_bg from '../../images/bg/instrument.jpg';
import yellow_icon1 from '../../images/challenge/Y1.png';
import yellow_icon2 from '../../images/challenge/Y2.png';
import yellow_icon3 from '../../images/challenge/Y3.png';
import yellow_icon4 from '../../images/challenge/Y4.png';
import yellow_icon5 from '../../images/challenge/Y5.png';
import logo13 from '../../images/enterprise-logo/logo/logo13.png';
import logo14 from '../../images/enterprise-logo/logo/logo14.png';
import logo23 from '../../images/enterprise-logo/logo/logo23.png';
import logo29 from '../../images/enterprise-logo/logo/logo29.png';
import logo8 from '../../images/enterprise-logo/logo/logo8.png';
import logo9 from '../../images/enterprise-logo/logo/logo9.png';
import classify_instrument from '../../images/industry/instrument1.png';
import screenshot_instrument1 from '../../images/screenshot/instrument/instrument1.png';
import screenshot_instrument2 from '../../images/screenshot/instrument/instrument2.png';
import screenshot_instrument3 from '../../images/screenshot/instrument/instrument3.png';
import screenshot_instrument4 from '../../images/screenshot/instrument/instrument4.png';
import screenshot_instrument5 from '../../images/screenshot/instrument/instrument5.png';
import screenshot_instrument6 from '../../images/screenshot/instrument/instrument6.png';

const instrument = {
    bgUrl: instrument_bg,
    classifyIcon: classify_instrument,
    title: '专业仪表仪器管理',
    content:
        '仪器仪表是用以检出、测量、观察、计算各种成分的设备。<br/> 我国已步入仪器仪表生产大国行列，通过多年发展已具备了相当的产业规模。',
    list1: [
        {
            imgUrl: yellow_icon1,
            describe: '五金机械产品品类多、 产品零散、管理难度大。',
            rgba: '248, 247, 207, 0.5'
        },
        {
            imgUrl: yellow_icon2,
            describe: '订单交付周期短，需要快速采购、备货便于完成交付。',
            rgba: '248, 247, 207, 0.5'
        },
        {
            imgUrl: yellow_icon3,
            describe: '供应渠道多，不同产品的供应商有不同的优惠政策，才能保持长久的合作。',
            rgba: '248, 247, 207, 0.5'
        },
        {
            imgUrl: yellow_icon4,
            describe: '销售人员在外需要实时查询库存，方便销售开单。',
            rgba: '248, 247, 207, 0.5'
        },
        {
            imgUrl: yellow_icon5,
            describe: '产品零散，库存存放杂乱无章。',
            rgba: '248, 247, 207, 0.5'
        }
    ],
    list2: [
        {
            name: '货品',
            describe: '货品分类管理'
        },
        {
            name: '报表',
            describe: '销售报表查询'
        },
        {
            name: '价格',
            describe: '客户价格策略'
        },
        {
            name: '核销',
            describe: '应付账款统一核销'
        },
        {
            name: '以销定',
            describe: '以销定购'
        },
        {
            name: '查询',
            describe: '账款报表实时查询'
        }
    ],
    list3: [
        {
            imgUrl: screenshot_instrument1,
            name: '货品分类管理'
        },
        {
            imgUrl: screenshot_instrument2,
            name: '销售报表'
        },
        {
            imgUrl: screenshot_instrument3,
            name: '客户价格策略'
        },
        {
            imgUrl: screenshot_instrument4,
            name: '应付账款统一核销'
        },
        {
            imgUrl: screenshot_instrument5,
            name: '以销定购'
        },
        {
            imgUrl: screenshot_instrument6,
            name: '账款报表实时查询'
        }
    ],
    list4: [
        {
            imgUrl: logo23,
            name: '恩特机械'
        },
        {
            imgUrl: logo13,
            name: '虎山电子'
        },
        {
            imgUrl: logo9,
            name: '全朗'
        },
        {
            imgUrl: logo14,
            name: '金炬实业'
        },
        {
            imgUrl: logo29,
            name: 'IZIT'
        },
        {
            imgUrl: logo8,
            name: 'Hightech'
        }
    ]
};

export default instrument;
