import sport_bg from '../../images/bg/sport.png';
import yellow_icon1 from '../../images/challenge/Y1.png';
import yellow_icon2 from '../../images/challenge/Y2.png';
import yellow_icon3 from '../../images/challenge/Y3.png';
import yellow_icon4 from '../../images/challenge/Y4.png';
import yellow_icon5 from '../../images/challenge/Y5.png';
import enterprise_sport1 from '../../images/enterprise-logo/sport/sport1.png';
import enterprise_sport2 from '../../images/enterprise-logo/sport/sport2.png';
import enterprise_sport3 from '../../images/enterprise-logo/sport/sport3.png';
import enterprise_sport4 from '../../images/enterprise-logo/sport/sport4.png';
import enterprise_sport5 from '../../images/enterprise-logo/sport/sport5.png';
import enterprise_sport6 from '../../images/enterprise-logo/sport/sport6.png';
import classify_sport from '../../images/industry/sport1.png';
import screenshot_sport1 from '../../images/screenshot/sport/sport1.png';
import screenshot_sport2 from '../../images/screenshot/sport/sport2.png';
import screenshot_sport3 from '../../images/screenshot/sport/sport3.png';
import screenshot_sport4 from '../../images/screenshot/sport/sport4.png';
import screenshot_sport5 from '../../images/screenshot/sport/sport5.png';
import screenshot_sport6 from '../../images/screenshot/sport/sport6.png';

const sport = {
    bgUrl: sport_bg,
    classifyIcon: classify_sport,
    title: '专业运动户外管理',
    content:
        '运动户外指各种运动相关用品以及户外活动所需用品，<br/> 为户外运动爱好者提供种类繁多的器具或辅助用品。',
    list1: [
        {
            imgUrl: yellow_icon1,
            describe:
                '户外行业货品品类多、品牌多、但功能雷同、形状不一，时常出现销售单里选错货品。',
            rgba: '248, 247, 207, 0.5'
        },
        {
            imgUrl: yellow_icon2,
            describe: '客户流动性大，需要给客户提供专属的优惠折扣，才能长期吸引客户。',
            rgba: '248, 247, 207, 0.5'
        },
        {
            imgUrl: yellow_icon3,
            describe: '门店租金高，需要精打细算，除收支记帐外，还要全面的财务管理。',
            rgba: '248, 247, 207, 0.5'
        },
        {
            imgUrl: yellow_icon4,
            describe: '连锁门店之间需要查询库存，及时调货，才能完成销售。',
            rgba: '248, 247, 207, 0.5'
        },
        {
            imgUrl: yellow_icon5,
            describe:
                '运动户外货品使用率高，客户对货品的要求高，单一的规格已不能满足其需求，货品需要设置多个规格。',
            rgba: '248, 247, 207, 0.5'
        }
    ],
    list2: [
        {
            name: '货品',
            describe: '货品分类管理'
        },
        {
            name: '策略',
            describe: '价格策略管理'
        },
        {
            name: '提醒',
            describe: '预交日期提醒'
        },
        {
            name: '供应商',
            describe: '供应商价格策略'
        },
        {
            name: '下单',
            describe: '移动端快速下单'
        },
        {
            name: '储位',
            describe: '仓库储位精细化管理'
        }
    ],
    list3: [
        {
            imgUrl: screenshot_sport1,
            name: '精准查询货品'
        },
        {
            imgUrl: screenshot_sport2,
            name: '价格策略管理'
        },
        {
            imgUrl: screenshot_sport3,
            name: '账款管理'
        },
        {
            imgUrl: screenshot_sport4,
            name: '库存调拨'
        },
        {
            imgUrl: screenshot_sport5,
            name: '多规格管理'
        },
        {
            imgUrl: screenshot_sport6,
            name: '出入库明细表'
        }
    ],
    list4: [
        {
            imgUrl: enterprise_sport1,
            name: 'QIANGHUAI'
        },
        {
            imgUrl: enterprise_sport2,
            name: 'JACKCOME'
        },
        {
            imgUrl: enterprise_sport3,
            name: 'MOTO'
        },
        {
            imgUrl: enterprise_sport4,
            name: '豪园'
        },
        {
            imgUrl: enterprise_sport5,
            name: '狄腾马'
        },
        {
            imgUrl: enterprise_sport6,
            name: 'IZIT'
        }
    ]
};

export default sport;
