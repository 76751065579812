import pack_bg from '../../images/bg/pack.png';
import orange_icon1 from '../../images/challenge/O1.png';
import orange_icon2 from '../../images/challenge/O2.png';
import orange_icon3 from '../../images/challenge/O3.png';
import orange_icon4 from '../../images/challenge/O4.png';
import orange_icon5 from '../../images/challenge/O5.png';
import logo13 from '../../images/enterprise-logo/logo/logo13.png';
import logo14 from '../../images/enterprise-logo/logo/logo14.png';
import logo25 from '../../images/enterprise-logo/logo/logo25.png';
import logo29 from '../../images/enterprise-logo/logo/logo29.png';
import logo8 from '../../images/enterprise-logo/logo/logo8.png';
import logo9 from '../../images/enterprise-logo/logo/logo9.png';
import classify_pack from '../../images/industry/pack1.png';
import screenshot_pack1 from '../../images/screenshot/pack/pack1.png';
import screenshot_pack2 from '../../images/screenshot/pack/pack2.png';
import screenshot_pack3 from '../../images/screenshot/pack/pack3.png';
import screenshot_pack4 from '../../images/screenshot/pack/pack4.png';
import screenshot_pack5 from '../../images/screenshot/pack/pack5.png';
import screenshot_pack6 from '../../images/screenshot/pack/pack6.png';

const pack = {
    bgUrl: pack_bg,
    classifyIcon: classify_pack,
    title: '专业印刷包装管理',
    content:
        '包装印刷，以各种包装材料为主要产品的包装行业以及印刷行业。<br/> 印刷的主要要素有纸张，油墨，色彩等。',
    list1: [
        {
            imgUrl: orange_icon1,
            describe: '印刷包装货品种类多，范围广，技术更新快，货品管理困难。',
            rgba: '250, 231, 203, 0.5'
        },
        {
            imgUrl: orange_icon2,
            describe: '大型的印刷包装流程较为复杂，管理混乱，需要设立专门的部门，方便管理。',
            rgba: '250, 231, 203, 0.5'
        },
        {
            imgUrl: orange_icon3,
            describe: '订单的生成安排不能达到时间上的最优化，仓库的安排不能达到空间的最优化。',
            rgba: '250, 231, 203, 0.5'
        },
        {
            imgUrl: orange_icon4,
            describe: '管理程序错综复杂，信息流通慢，处理过程繁琐，需要随时了解商品的库存情况。',
            rgba: '250, 231, 203, 0.5'
        },
        {
            imgUrl: orange_icon5,
            describe: '印刷包装货品提倡环保意识，原材料可再次利用，需要进行成本核算。',
            rgba: '250, 231, 203, 0.5'
        }
    ],
    list2: [
        {
            name: '货品',
            describe: '货品分类管理'
        },
        {
            name: '策略',
            describe: '价格策略管理'
        },
        {
            name: '提醒',
            describe: '预交日期提醒'
        },
        {
            name: '供应商',
            describe: '供应商价格策略'
        },
        {
            name: '下单',
            describe: '移动端快速下单'
        },
        {
            name: '储位',
            describe: '仓库储位精细化管理'
        }
    ],
    list3: [
        {
            imgUrl: screenshot_pack1,
            name: '货品分类管理'
        },
        {
            imgUrl: screenshot_pack2,
            name: '部门管理'
        },
        {
            imgUrl: screenshot_pack3,
            name: '订单管理'
        },
        {
            imgUrl: screenshot_pack4,
            name: '精准查询货品'
        },
        {
            imgUrl: screenshot_pack5,
            name: '成本核算'
        },
        {
            imgUrl: screenshot_pack6,
            name: '移动端查询库存'
        }
    ],
    list4: [
        {
            imgUrl: logo25,
            name: '樱之恋'
        },
        {
            imgUrl: logo13,
            name: '虎山电子'
        },
        {
            imgUrl: logo9,
            name: '全朗'
        },
        {
            imgUrl: logo14,
            name: '金炬实业'
        },
        {
            imgUrl: logo29,
            name: 'IZIT'
        },
        {
            imgUrl: logo8,
            name: 'Hightech'
        }
    ]
};

export default pack;
