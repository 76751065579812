import chemical_bg from '../../images/bg/chemical.png';
import orange_icon1 from '../../images/challenge/O1.png';
import orange_icon2 from '../../images/challenge/O2.png';
import orange_icon3 from '../../images/challenge/O3.png';
import orange_icon4 from '../../images/challenge/O4.png';
import orange_icon5 from '../../images/challenge/O5.png';
import enterprise_chemical1 from '../../images/enterprise-logo/chemical/chemical1.png';
import enterprise_chemical2 from '../../images/enterprise-logo/chemical/chemical2.png';
import enterprise_chemical3 from '../../images/enterprise-logo/chemical/chemical3.png';
import enterprise_chemical4 from '../../images/enterprise-logo/chemical/chemical4.png';
import enterprise_chemical5 from '../../images/enterprise-logo/chemical/chemical5.png';
import enterprise_chemical6 from '../../images/enterprise-logo/chemical/chemical6.png';
import classify_chemical from '../../images/industry/chemical1.png';
import screenshot_chemical1 from '../../images/screenshot/chemical/chemical1.png';
import screenshot_chemical2 from '../../images/screenshot/chemical/chemical2.png';
import screenshot_chemical3 from '../../images/screenshot/chemical/chemical3.png';
import screenshot_chemical4 from '../../images/screenshot/chemical/chemical4.png';
import screenshot_chemical5 from '../../images/screenshot/chemical/chemical5.png';
import screenshot_chemical6 from '../../images/screenshot/chemical/chemical6.png';

const chemical = {
    bgUrl: chemical_bg,
    classifyIcon: classify_chemical,
    title: '专业橡塑化工管理',
    content:
        '天然橡胶是工业生产不可或缺的材料，中国是全球最大的橡胶消费国。<br/> 在跨进21世纪后，中国塑料行业进入了一个高速发展的时间段。<br/>塑料在中国市场中无论是生产量、进口量，还是消费量都在稳步的增长。',
    list1: [
        {
            imgUrl: orange_icon1,
            describe: '橡塑化工品类多、规格多、不标准、货品数量庞大，货品管理极为困难。',
            rgba: '250, 231, 203, 0.5'
        },
        {
            imgUrl: orange_icon2,
            describe: '批发销售与采购金额大，款项出入多，容易形成财务漏洞，损失巨大。',
            rgba: '250, 231, 203, 0.5'
        },
        {
            imgUrl: orange_icon3,
            describe:
                '橡塑化工货品经常没有现货，按客户需求去采购、订货，没有良好的客户跟踪系统，就会出现漏采、或者交付不及时等问题。',
            rgba: '250, 231, 203, 0.5'
        },
        {
            imgUrl: orange_icon4,
            describe:
                '现货销售时，时需要经常查同款货品，不同规格的库存量，不能快速查询就会影响销售。',
            rgba: '250, 231, 203, 0.5'
        },
        {
            imgUrl: orange_icon5,
            describe: '按平方米销售，按大件（张、卷）出库， 出库单位与销售单位不同。',
            rgba: '250, 231, 203, 0.5'
        }
    ],
    list2: [
        {
            name: '货品',
            describe: '货品分类管理'
        },
        {
            name: '策略',
            describe: '价格策略管理'
        },
        {
            name: '提醒',
            describe: '预交日期提醒'
        },
        {
            name: '供应商',
            describe: '供应商价格策略'
        },
        {
            name: '下单',
            describe: '移动端快速下单'
        },
        {
            name: '储位',
            describe: '仓库储位精细化管理'
        }
    ],
    list3: [
        {
            imgUrl: screenshot_chemical1,
            name: '货品分类管理'
        },
        {
            imgUrl: screenshot_chemical2,
            name: '账款管理'
        },
        {
            imgUrl: screenshot_chemical3,
            name: '客户管理'
        },
        {
            imgUrl: screenshot_chemical4,
            name: '多单位管理'
        },
        {
            imgUrl: screenshot_chemical5,
            name: '账款报表实时查询'
        },
        {
            imgUrl: screenshot_chemical6,
            name: '移动端查询库存'
        }
    ],
    list4: [
        {
            imgUrl: enterprise_chemical1,
            name: '联吉'
        },
        {
            imgUrl: enterprise_chemical2,
            name: '山西天脊'
        },
        {
            imgUrl: enterprise_chemical3,
            name: 'DOLSI'
        },
        {
            imgUrl: enterprise_chemical4,
            name: '金炬实业'
        },
        {
            imgUrl: enterprise_chemical5,
            name: '恩际'
        },
        {
            imgUrl: enterprise_chemical6,
            name: 'Hightech'
        }
    ]
};

export default chemical;
